import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Slider.css';

function Slider() {
	return (
		<div>
			<Carousel className="carousel">
				<Carousel.Item>
					<img
						className="d-block w-100"
						height="500"
						src="https://res.cloudinary.com/dchcqnhr8/image/upload/v1647694416/carousel3_repuio.png"
						alt="First slide"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						height="500"
						src="https://res.cloudinary.com/dchcqnhr8/image/upload/v1647694372/carousel_kjr4pm.png"
						alt="Second slide"
					/>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						height="500"
						src="https://res.cloudinary.com/dchcqnhr8/image/upload/v1647694395/carousel2_qx4mcx.png"
						alt="Third slide"
					/>
				</Carousel.Item>
			</Carousel>
		</div>
	);
}

export default Slider;
