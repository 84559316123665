import React from 'react'
import { Button } from 'react-bootstrap'

const WhatsAppButton = () => {
  return (
    <Button
      href='https://wa.me/+2250141337630'
      target='_blank'
      rel='noreferrer'
      style={{
        textDecoration: 'none',
        position: 'fixed',
        width: '60px',
        height: '60px',
        bottom: '40px',
        right: '40px',
        backgroundColor: '#25d366',
        borderRadius: '50%',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 100,
      }}
    >
      <i
        style={{
          fontSize: '30px',
          marginTop: '8px',
          color: 'white',
        }}
        className='fa fa-whatsapp'
      ></i>
    </Button>
  )
}

export default WhatsAppButton
