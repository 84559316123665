import { useTranslation } from 'react-i18next'
import { Card, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
//import Rating from './Rating';
import axios from 'axios'
import { useContext } from 'react'
import { Store } from '../Store'

function Product(props) {
  const { t } = useTranslation(['product'])
  const { product } = props

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const {
    cart: { cartItems },
  } = state

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id)
    const quantity = existItem ? existItem.quantity + 1 : 1
    const { data } = await axios.get(`/api/products/${item._id}`)
    if (data.countInStock < quantity) {
      window.alert(`${t('sorryProductIsOutOfStock')}`)
      return
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    })
  }

  return (
    <Card>
      <Link to={`/product/${product.slug}`}>
        <img src={product.image} className='card-img-top' alt={product.name} />
      </Link>
      <Card.Body style={{ paddinBottom: '10px' }}>
        <Link to={`/product/${product.slug}`}>
          <Card.Title>{product.name}</Card.Title>
        </Link>
        <Row>
          <Col>
            <Card.Text style={{ fontSize: '20px' }}>
              {product.price} CFA
            </Card.Text>
          </Col>
          {product.realPrice && (
            <Col>
              <Card.Text
                style={{
                  textDecoration: 'line-through',
                  color: 'gray',
                }}
              >
                {product.realPrice} CFA
              </Card.Text>
            </Col>
          )}
        </Row>

        {product.productytlink && (
          <Card.Text>
            <a
              href={`${product.productytlink}`}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none', color: 'gray' }}
            >
              {t('productVideo')}
            </a>
          </Card.Text>
        )}
        {product.countInStock === 0 ? (
          <Button variant='light' disabled>
            {t('outOfStock')}
          </Button>
        ) : (
          <Button
            onClick={() => addToCartHandler(product)}
            style={{ marginTop: '20px' }}
          >
            {t('addToCart')}
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}
export default Product
