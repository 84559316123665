import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useReducer } from 'react'
import Chart from 'react-google-charts'
import axios from 'axios'
import { Store } from '../Store'
import { getError } from '../utils'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      }
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
export default function DashboardScreen() {
  const { t } = useTranslation(['dashboard'])
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const { state } = useContext(Store)
  const { userInfo } = state

  const date = `${t('date')}`
  const sales = `${t('sales')}`
  const category = `${t('category')}`
  const products = `${t('products')}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/orders/summary', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        })

        dispatch({ type: 'FETCH_SUCCESS', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        })
      }
    }
    fetchData()
  }, [userInfo])

  return (
    <div>
      <h1>{t('dashboard')}</h1>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant='danger'>{error}</MessageBox>
      ) : (
        <>
          <Row>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary?.users && summary?.users[0]
                      ? summary?.users[0]?.numUsers
                      : 0}
                  </Card.Title>
                  <Card.Text> {t('users')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary?.orders && summary?.users[0]
                      ? summary?.orders[0]?.numOrders
                      : 0}
                  </Card.Title>
                  <Card.Text> {t('orders')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary?.orders && summary?.users[0]
                      ? summary?.orders[0]?.totalSales.toFixed(2)
                      : 0}{' '}
                    FCFA
                  </Card.Title>
                  <Card.Text> {t('orders')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className='my-3'>
            <h2>{t('sales')}</h2>
            {summary?.dailyOrders?.length === 0 ? (
              <MessageBox>{t('noSale')}</MessageBox>
            ) : (
              <Chart
                width='100%'
                height='400px'
                chartType='AreaChart'
                loader={<div>{t('loadingChart')}...</div>}
                data={[
                  [date, sales],
                  ...summary.dailyOrders.map((x) => [x._id, x.sales]),
                ]}
              ></Chart>
            )}
          </div>
          <div className='my-3'>
            <h2>{t('categories')}</h2>
            {summary?.productCategories?.length === 0 ? (
              <MessageBox>{t('noCategory')}</MessageBox>
            ) : (
              <Chart
                width='100%'
                height='400px'
                chartType='PieChart'
                loader={<div>{t('loadingChart')}...</div>}
                data={[
                  [category, products],
                  ...summary.productCategories.map((x) => [x._id, x.count]),
                ]}
              ></Chart>
            )}
          </div>
        </>
      )}
    </div>
  )
}
