import React from 'react';

function AboutScreen() {
	return (
		<div>
			<h1>QUI SOMMES-NOUS?</h1>
			<p>
				Chez LEBAZARDETRAB, notre mission est de vous offrir des produits (Vêtements, Chaussures, Montres,
				Accessoires... ) qui prouvent que vous êtes importants pour nous. Non seulement nos produits sont au top
				de la tendance, mais ils sont aussi de la meilleure qualité. Nous avons démarré en tant que petite
				entreprise à Abidjan en Côte d'Ivoire en 2021 de façon officielle et nous n’avons cessé depuis de
				fournir à nos clients des produits qui les rendent heureux, à des prix qui les rendent encore plus
				heureux. Nos clients restent au top de nos priorités et nous travaillons dur pour développer avec eux
				une relation sur le long terme, basée sur la confiance et l’authenticité.
			</p>
		</div>
	);
}

export default AboutScreen;
