import React from 'react';

function HelpScreen() {
	return (
		<div>
			<h1>QUESTIONS FRÉQUEMMENT POSÉES</h1>
			<hr />
			<h2>Comment commander sur LEBAZARDETRAB?</h2>
			<p>
				<ol>
					<li>Créer un compte en cliquant sur le bouton "S'incrire" en haut à droite</li>
					<li>
						{' '}
						Sélectionner l'article ou les articles de votre choix puis cliquer sur le bouton ajouter au
						panier
					</li>
					<li>
						{' '}
						Cliquer sur le bouton Panier avec la petite icone en haut à droite pour voir l'ensemble des
						produits que vous désisez commander.
					</li>
					<li>Valider la commande si elle correspond à ce que vous désirez</li>
					<li> Remplisser le formulaire puis sélectionner le moyen de paiement</li>
					<li> Confirmer la commande après vérification des informations saisies. Et c'est tout :-)</li>
				</ol>
			</p>
		</div>
	);
}

export default HelpScreen;
