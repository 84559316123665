import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";

function AmazonProduct(props) {
  const { t } = useTranslation(["product"]);
  const { product } = props;

  return (
    <Card>
      <a href={product.productredirectlink} target="_blank" rel="noreferrer">
        <img src={product.image} className="card-img-top" alt={product.name} />
      </a>
      <Card.Body style={{ paddinBottom: "10px" }}>
        <a
          href={product.productredirectlink}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#615e5e" }}
        >
          <Card.Title>{product.name}</Card.Title>
        </a>
        <Row>
          <Col>
            <Card.Text style={{ color: "#e12929", fontSize: "2em" }}>
              {product.price} €
            </Card.Text>
          </Col>
        </Row>

        {product.productredirectlink && (
          <Card.Text>
            <a
              href={product.productredirectlink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#cac5c5" }}
            >
              {t("seemore")}
            </a>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
}
export default AmazonProduct;
