import { useTranslation } from 'react-i18next'
import { useEffect, useReducer } from 'react'
import axios from 'axios'
import logger from 'use-reducer-logger'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Product from '../components/Product'
import AmazonProduct from '../components/AmazonProduct'
import { Helmet } from 'react-helmet-async'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'
import Slider from '../components/Slider'

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true }
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false }
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload }
    case 'FETCH_REQUEST_AMAZON_PRODUCT':
      return { ...state, loading: true }
    case 'FETCH_SUCCESS_AMAZON_PRODUCT':
      return { ...state, amazonProducts: action.payload, loading: false }
    case 'FETCH_FAIL_AMAZON_PRODUCT':
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

function HomeScreen() {
  const { t } = useTranslation(['home'])
  const [{ loading, error, products, amazonProducts }, dispatch] = useReducer(
    reducer,
    {
      products: [],
      amazonProducts: [],
      loading: true,
      error: '',
    }
  )

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      try {
        const result = await axios.get('/api/products')
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message })
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST_AMAZON_PRODUCT' })
      try {
        const result = await axios.get('/api/amazon-products')
        dispatch({
          type: 'FETCH_SUCCESS_AMAZON_PRODUCT',
          payload: result.data,
        })
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL_AMAZON_PRODUCT', payload: err.message })
      }
    }
    fetchData()
  }, [])

  return (
    <div>
      <Helmet>
        <title>LEBAZARDETRAB</title>
      </Helmet>
      <Slider />
      <h1 style={{ paddingTop: '50px' }}>{t('featuredProducts')}</h1>
      <div className='products' style={{ paddingBottom: '20px' }}>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant='danger'>{error}</MessageBox>
        ) : (
          <>
            <Row>
              {products.map((product) => (
                <Col key={product.slug} sm={6} md={4} lg={3} className='mb-3'>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </div>
  )
}
export default HomeScreen
